<template>
  <Ships />
</template>

<script>
import Ships from "@/components/Configurations/Ships";

export default {
  components: {
    Ships,
  },
};
</script>
