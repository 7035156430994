<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-add</v-icon>{{ $t("configurations.create_ship") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn depressed icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-progress-linear
        v-if="loading"
        color="blue darken-4"
        indeterminate
        height="6"
      ></v-progress-linear>
      <v-card-text style="overflow:auto;">
        <v-form ref="form" class="mt-10">
          <ShipDialog :ship="item" :key="componentKey" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed color="primary" text @click="save">
          {{ $t("global.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const DEFAULT_ITEM = {
  imo: "",
  name: "",
  mmsi: null,
  loa: null,
  gt: null,
  nt: null,
  breadth_moulded: null,
  maximum_draught: null,
  ship_flag_country: null,
  call_sign: null,
};
import ShipDialog from "./ShipDialog";
export default {
  props: ["open"],
  components: {
    ShipDialog,
  },
  data() {
    return {
      item: Object.assign({}, DEFAULT_ITEM),
      loading: false,
      componentKey: 0,
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    countriesList() {
      return this.$store.state.ships.countriesList;
    },
  },

  watch: {
    isOpen(val) {
      if (val) {
        if (this.$refs.form) this.$refs.form.resetValidation();
        this.item = Object.assign({}, DEFAULT_ITEM);
      }
    },
  },
  methods: {
    close() {
      if (this.$refs.form) this.$refs.form.reset();
      this.isOpen = false;
      this.componentKey++;
    },
    save() {
      if (this.$refs.form.validate()) {
        let shipFlagId = this.countriesList.find((country) => {
          return (
            country.code === this.item.ship_flag_country.split("-")[0].trim()
          );
        }).id;
        
        this.loading = true;
        this.$store
          .dispatch("ships/CREATE_SHIP", {
            //Required fields
            imo: this.item.imo,
            name: this.item.name,
            maximum_draught: this.item.maximum_draught,
            loa: this.item.loa,
            ship_flag_country: shipFlagId,
            //Non required fields
            beam: this.item.breadth_moulded,
            call_sign: this.item.call_sign,
            mmsi: this.item.mmsi,
            gt: this.item.gt,
            nt: this.item.nt,
            file: this.item.file,
          })
          .then(() => {
            this.loading = false;
            this.close();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
