<template>
  <v-row>
    <v-col cols="12" sm="4">
      <div
        class="image-input"
        :style="{ 'background-image': `url(${image})` }"
        @click="activateFileInput"
      >
        <span v-if="!image" class="placeholder">
          {{ $t("ship.choose_image") }}
        </span>
        <v-tooltip v-if="image" left color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn depressed
              v-bind="attrs"
              v-on="on"
              class="remove-image-button"
              x-small
              fab
              dark
              color="rgb(166, 82, 82)"
              @click.stop="removeImage"
            >
              <v-icon>mdi-close-thick</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("ship.remove_image") }} </span>
        </v-tooltip>
        <input
          class="file-input"
          ref="fileInput"
          type="file"
          @input="onFileChange"
        />
      </div>
      <v-text-field
        v-model="ship.maximum_draught"
        :label="$t('ship.maximum_draught')"
        outlined
        type="number"
        :rules="maximumDraughtRules"
        required
        onkeydown="return event.keyCode !== 69"
        counter="50"
        min="0"
      />

      <v-select
        :items="items"
        v-model="ship.ship_flag_country"
        :label="$t('ship.ship_flag')"
        :rules="shipFlagRules"
        :menu-props="{ offsetY: true }"
        outlined
      ></v-select>
    </v-col>

    <v-col cols="12" sm="4">
      <v-text-field
        v-model="ship.imo"
        required
        :label="$t('ship.imo')"
        :rules="codeRules"
        counter="255"
        outlined
      />

      <v-text-field
        v-model="ship.call_sign"
        :label="$t('ship.call_sign')"
        :rules="callSignRules"
        counter="255"
        outlined
      />

      <v-text-field
        v-model="ship.nt"
        :label="$t('ship.nt')"
        outlined
        type="number"
        :rules="ntRules"
        onkeydown="return event.keyCode !== 69"
        counter="50"
        min="0"
      />

      <v-text-field
        v-model="ship.breadth_moulded"
        :label="$t('ship.beam')"
        outlined
        type="number"
        :rules="beamRules"
        onkeydown="return event.keyCode !== 69"
        counter="50"
        min="0"
      />
    </v-col>

    <v-col cols="12" sm="4"
      ><v-text-field
        v-model="ship.name"
        required
        :label="$t('ship.name')"
        :rules="nameRules"
        counter="255"
        outlined
      />

      <v-text-field
        v-model="ship.mmsi"
        :label="$t('ais_ship.mmsi')"
        :rules="mmsiRules"
        counter="255"
        outlined
      />

      <v-text-field
        v-model="ship.gt"
        :label="$t('ship.gt')"
        outlined
        type="number"
        :rules="gtRules"
        onkeydown="return event.keyCode !== 69"
        counter="50"
        min="0"
      />

      <v-text-field
        v-model="ship.loa"
        :label="$t('ship.loa')"
        outlined
        type="number"
        :rules="loaRules"
        required
        onkeydown="return event.keyCode !== 69"
        counter="50"
        min="0"
      />
    </v-col>
  </v-row>
</template>

<script>
import configs from "@/helpers/configs";
export default {
  props: ["ship"],
  data() {
    return {
      codeRules: [
        (v) => !!v || this.$t("ship.imo_rule"),
        (v) =>
          (!!v && v.length <= 255) || this.$t("ship.character_limit_rule"),
      ],
      nameRules: [
        (v) => !!v || this.$t("ship.name_rule"),
        (v) =>
          (!!v && v.length <= 255) || this.$t("ship.character_limit_rule"),
      ],
      callSignRules: [
        (v) =>
          !v ||
          (!!v && v.length <= 255) ||
          this.$t("ship.character_limit_rule"),
      ],
      beamRules: [
        (v) =>
          !v ||
          (!!v && v.length <= 50) ||
          this.$t("ship.character_limit_rule"),
        (v) => (v ? v >= 0 || this.$t("ship.negative_rule") : true),
      ],
      gtRules: [
        (v) =>
          !v ||
          (!!v && v.length <= 50) ||
          this.$t("ship.character_limit_rule"),
        (v) => (v ? v >= 0 || this.$t("ship.negative_rule") : true),
      ],
      ntRules: [
        (v) =>
          !v ||
          (!!v && v.length <= 50) ||
          "Ultrapassou o limite de caracteres!",
        (v) => (v ? v >= 0 || this.$t("ship.negative_rule") : true),
      ],
      mmsiRules: [
        (v) =>
          !v ||
          (!!v && v.length <= 255) ||
          this.$t("ship.character_limit_rule"),
      ],
      loaRules: [
        (v) =>
          !v ||
          (!!v && v.length <= 50) ||
          this.$t("ship.character_limit_rule"),
        (v) => !!v || this.$t("ship.negative_rule"),
        (v) => (v ? v >= 0 || this.$t("ship.negative_rule") : true),
      ],
      maximumDraughtRules: [
        (v) =>
          !v ||
          (!!v && v.length <= 50) ||
          this.$t("ship.character_limit_rule"),
        (v) => !!v || this.$t("ship.maximum_draught_rule"),
        (v) => (v ? v >= 0 || this.$t("ship.negative_rule") : true),
      ],
      shipFlagRules: [(v) => !!v || this.$t("ship.ship_flag_rule")],
      image: null,
    };
  },
  mounted() {
    if (this.ship.id)
      this.image = "/api/v1/ships/" + (this.ship.imo ?? 0) + "/photo/" + this.locode.code;
  },
  computed: {
    locode() {
      return configs.getLocode();
    },
    items() {
      return this.$store.state.ships.countriesList.map((country) => {
        return country.code + " - " + country.name;
      });
    },
  },
  methods: {
    activateFileInput() {
      this.$refs.fileInput.click();
    },
    createImage(file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.image = reader.result;
      };
      this.ship.file = file;
    },
    onFileChange() {
      const input = this.$refs.fileInput;
      let file = input?.files[0];
      if (!file) {
        return;
      }
      this.createImage(file);
    },
    removeImage() {
      this.image = null;
      this.ship.file = null;
    },
  },
};
</script>

<style scoped>
.image-input {
  display: block;
  height: 142px;
  width: 268px;
  margin-bottom: 30px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
  border-radius: 6px;
  border-color: rgba(126, 116, 116, 0.891);
  border-style: solid;
  border-width: 1px;
}

.image-input:hover {
  border-radius: 6px;
  border-color: black;
}

.remove-image-button {
  justify-content: center;
  align-items: center;
  top: 110px;
  left: 250px;
  position: absolute;
  background-color: rgb(170, 40, 40) !important;
}

.remove-image-button:hover {
  background-color: rgb(172, 61, 61) !important;

  border-width: thick !important;
  border-color: black !important;
}

.placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  font-family: Helvetica;
}
.placeholder:hover {
  background: #e0e0e0;
  border-radius: 6px;
  border-color: black;
}

.file-input {
  display: none;
}
</style>
