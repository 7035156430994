<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("configurations.ships") }}
        </p>
        <p class="text-caption">
          {{ $t("configurations.ship_information") }}
        </p>
      </div>
      <v-spacer></v-spacer>

      <v-btn
        v-if="hasUserPermissionToViewEditOrManage('SHIPS')"
        class="mb-5"
        color="primary"
        depressed
        @click="add"
      >
        <v-icon left>mdi-plus-box-multiple</v-icon>
        {{ $t("configurations.new_ship") }}
      </v-btn>
    </div>

    <List @edit="edit" :key="componentKey" />

    <CreateShipDialog :open.sync="openAddShipDialog" />

    <EditShipDialog
      :open.sync="openEditShipDialog"
      :selectedShip="selectedShip"
    />
  </div>
</template>

<script>
import List from "./Ships/List";
import CreateShipDialog from "./Ships/CreateShipDialog";
import EditShipDialog from "./Ships/EditShipDialog";
export default {
  components: {
    List,
    CreateShipDialog,
    EditShipDialog,
  },
  data() {
    return {
      componentKey: 0,
      openAddShipDialog: false,
      openEditShipDialog: false,
      selectedShip: null,
    };
  },
  beforeDestroy() {
    this.$store.dispatch("ships/RESET");
  },
  watch: {
    openAddShipDialog(val) {
      if (val == false) this.forceRerender();
    },
    openEditShipDialog(val) {
      if (val == false) this.forceRerender();
    },
  },
  methods: {
    add() {
      this.openAddShipDialog = true;
    },
    edit(ship) {
      this.selectedShip = ship;
      this.openEditShipDialog = true;
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
};
</script>
