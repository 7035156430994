<template>
  <div style="max-height: calc(100vh - 179px); overflow-y: auto">
    <v-data-table
      :footer-props="{
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        'items-per-page-options': [10],
        disableItemsPerPage: true,
      }"
      :loading="loading"
      :page.sync="currentPage"
      :headers="headers"
      :items="items"
      :server-items-length="totalNumberOfShips"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @update:options="handleUpdate('options', $event)"
      item-key="id"
      fixed-header
      must-sort
    >
      <template v-slot:top>
        <v-text-field
          v-model="searchedTerm"
          @input="filterShips"
          single-line
          :label="$t('configurations.search_ships')"
          prepend-inner-icon="mdi-magnify"
          outlined
        />
      </template>
      <template v-slot:item.ship_flag_name="{ item }">
        <country-flag
          v-if="!!item.ship_flag_code"
          :country="item.ship_flag_code"
          rounded
        />
      </template>
      <template
        v-if="hasUserPermissionToViewEditOrManage('SHIPS')"
        v-slot:item.actions="{ item }"
      >
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              icon
              v-bind="attrs"
              v-on="on"
              @click.stop="edit(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("global.edit") }}</span>
        </v-tooltip>
      </template>
      <template #footer.prepend>
        <v-spacer />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { debounce } from "debounce";
export default {
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "",
          value: "ship_flag_name",
        },
        {
          text: this.$t("ship.name"),
          value: "name",
        },
        {
          text: this.$t("ship.imo"),
          value: "imo",
        },
        {
          text: this.$t("ship.mmsi"),
          value: "mmsi",
        },
        {
          text: this.$t("ship.call_sign"),
          value: "call_sign",
        },
        {
          text: this.$t("ship.ship_type"),
          value: "ship_type_description",
        },
        {
          text: this.$t("ship.loa"),
          value: "loa",
        },
        {
          text: this.$t("ship.beam"),
          value: "breadth_moulded",
        },
        {
          text: this.$t("ship.maximum_draught"),
          value: "maximum_draught",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "30px",
        },
      ],
    };
  },
  mounted() {
    this.getAllCountries();
  },
  computed: {
    payload: {
      get() {
        return this.$store.state.ships.payload;
      },
      set(val) {
        this.$store.dispatch("ships/SET_PAYLOAD", val);
      },
    },
    items() {
      return this.$store.state.ships.list;
    },
    totalNumberOfShips() {
      return this.$store.state.ships.totalNumberOfShips;
    },
    itemsPerPage: {
      get() {
        return this.$store.state.ships.itemsPerPage;
      },
      set(val) {
        this.$store.dispatch("ships/SET_ITEMS_PER_PAGE", val);
      },
    },
    currentPage: {
      get() {
        return this.$store.state.ships.currentPage;
      },
      set(val) {
        this.$store.dispatch("ships/SET_CURRENT_PAGE", val);
      },
    },
    searchedTerm: {
      get() {
        return this.$store.state.ships.searchedTerm;
      },
      set(val) {
        this.$store.dispatch("ships/SET_SEARCHED_TERM", val);
      },
    },
    sortBy: {
      get() {
        return this.$store.state.ships.sortBy;
      },
      set(val) {
        this.$store.dispatch("ships/SET_SORT_BY", val);
      },
    },
    sortDesc: {
      get() {
        return this.$store.state.ships.sortDesc;
      },
      set(val) {
        this.$store.dispatch("ships/SET_SORT_DESC", val);
      },
    },
  },
  methods: {
    getAllCountries() {
      this.$store.dispatch("ships/GET_COUNTRIES_LIST");
    },
    getShips() {
      this.loading = true;
      this.$store.dispatch("ships/GET_SHIPS").then(() => {
        this.loading = false;
      });
    },
    edit(shipId) {
      this.$emit("edit", shipId);
    },
    handleUpdate: debounce(function (_, options) {
      this.payload = {
        search: this.searchedTerm,
        currentPage: options.page,
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        itemsPerPage: options.itemsPerPage,
      };
      this.getShips();
    }, 500),
    filterShips: debounce(function () {
      // Changes the current page to the first one to reset the search stop bugs from happening
      // like searching a name that has only one result and appearing an empty list to the user
      this.payload = { ...this.payload, search: this.searchedTerm };
      this.currentPage = 1;
      this.getShips();
    }, 500),
  },
};
</script>
