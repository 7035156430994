<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-toolbar dark color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-add</v-icon>{{ $t("configurations.edit_ship") }}
            {{ ship ? "- " + ship.name : "" }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn depressed icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-progress-linear
        v-if="loading"
        color="blue darken-4"
        indeterminate
        height="6"
      ></v-progress-linear>
      <v-card-text v-if="!!ship" style="overflow: auto">
        <v-form ref="form" class="mt-10">
          <ShipDialog :ship="ship" :key="componentKey" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed color="primary" text @click="save">
          {{ $t("global.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ShipDialog from "./ShipDialog";

export default {
  props: ["open", "selectedShip"],
  components: {
    ShipDialog,
  },
  data() {
    return {
      shipImage: null,
      ship: null,
      loading: false,
      componentKey: 0,
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    countriesList() {
      return this.$store.state.ships.countriesList;
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.loading = true;

        this.$store
          .dispatch("ships/GET_SHIP_BY_ID", this.selectedShip.id)
          .then((shipToEdit) => {
            this.ship = shipToEdit;
            this.ship.ship_flag_country = null;

            let country = this.countriesList.find((country) => {
              return country.id === this.ship.ship_flag_id;
            });

            if (country)
              this.ship.ship_flag_country = country.code + " - " + country.name;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.close();
          });
      }
    },
  },

  methods: {
    close() {
      if (this.$refs.form) this.$refs.form.reset();
      this.isOpen = false;
      this.ship = null;
      this.componentKey++;
    },
    save() {
      if (this.$refs.form.validate()) {
        let shipFlagId = this.countriesList.find((country) => {
          return (
            country.code === this.ship.ship_flag_country.split("-")[0].trim()
          );
        }).id;
        this.loading = true;
        this.$store
          .dispatch("ships/UPDATE_SHIP", {
            id: this.ship.id,
            //Required fields
            imo: this.ship.imo,
            name: this.ship.name,
            maximum_draught: this.ship.maximum_draught,
            loa: this.ship.loa,
            ship_flag_country: shipFlagId,
            //Non required fields
            mmsi: this.ship.mmsi,
            gt: this.ship.gt,
            nt: this.ship.nt,
            beam: this.ship.breadth_moulded,
            call_sign: this.ship.call_sign,
            file: this.ship.file,
          })
          .then(() => {
            this.loading = false;
            this.close();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
